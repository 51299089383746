import {CDHAppEnvironment} from "./environment.types";

export const environment = {
  production: false,
  version: CDHAppEnvironment.DEV,
  applicationType: "APPS",
  employeeApi: 'https://dev-epemployee.easypay-group.com/api/',
  cdhApi: 'https://dev-apim.easypay-group.com/external/cdh/api/',
  // cdhApi: 'http://localhost:2003/api/',
  iamApi: 'https://dev-apim.easypay-group.com/external/iam/api/',
  defaultTimezone: 'Europe/Brussels',
  baseUrl: "https://dev-apps.easypay-group.com",
  authenticationUrl: "https://dev-portal.easypay-group.com/login",
  logoutUrl: "https://dev-portal.easypay-group.com/logout",
  bypassSecurity: false,
  apimSubscriptionKey: "82157dfa92d44ff78f9dc99e07fc6dd3"
};
